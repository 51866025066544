<template>
	<el-container class="login-container">
		<img class="bg" :src="$staticUrl + 'images/login_bg.jpg'" />
		<div class="login-cont">
			<verify ref="ver" :config="verifyConfig" @change="syncData"></verify>
			<div class="login-buttons">
				<el-button type="primary" class="w-p100" @click="submit">登录</el-button>
			</div>
			<div class="login-foot">
				<!-- <a class="f12 c-grey" href="">企业微信登录</a> -->
				<router-link class="f12 c-grey" :to="{ name: 'register' }">登记咨询入驻</router-link>
			</div>
		</div>
	</el-container>
</template>
<script>
import { mapState } from "vuex"
import Verify from "@/components/Verify"
export default {
	computed: { ...mapState(["FVs", "user", "login"]) },
	data() {
		return {
			randomImgUrl: null,
			info: { mobile: "", code: "", authCode: "" },
			verifyConfig: {
				mobile: { icon: "icon-phone" },
				code: { icon: "icon-random" },
				authCode: { icon: "icon-message" }
			},
			loginFlag: false
		}
	},
	created() {
		if (this.user.mobile) {
			this.info.mobile = this.user.mobile
		}
		this.redirect = this.$query.redirect && decodeURIComponent(this.$query.redirect)
	},
	mounted() {},
	methods: {
		syncData(mobileInfo) {
			this.info = mobileInfo
		},
		navBack() {
			// if (!this.redirect) {
			// 	this.go(-1)
			// } else {
			this.go({
				path: this.redirect ? this.redirect : "index"
			})
			// }
		},
		submit() {
			if (this.loginFlag) {
				return false
			}
			this.$refs.ver.validate().then(() => {
				let { mobile, authCode } = this.info
				if (!/^(\d{11})$/.test(mobile)) {
					this.$toast("请输入11位手机号码!", "error")
					return false
				}
				this.loginFlag = true
				this.$Xhr({
					url: `/userLogin/login?code=${this.info.code}`,
					data: {
						mobile,
						authCode
					},
					method: "post"
				})
					.then(res => {
						console.log("登录成功")
						this.loginFlag = false
						this.$toast("登录成功")
						this.$store.commit("updateUser", res)
						this.$store.commit("updateLoginStatus", true)
						setTimeout(() => {
							this.navBack()
						}, 600)
					})
					.catch(() => {
						this.loginFlag = false
					})
			})
		}
	},
	components: {
		Verify
	}
}
</script>
<style lang="scss" scoped>
.bg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
}
.login-container {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.login-cont {
	width: 55.2rem;
	height: 40.9rem;
	padding: 7.4rem 8.5rem 5rem;
	margin: 0 auto;
	border-radius: 0.8rem;
	z-index: 1;
	background: #fff;
	@media screen and (max-width: 1024px) {
		width: auto;
		height: auto;
		padding: 4rem 3rem 2.5rem;
		margin: 0 2.5rem;
		border-radius: 0.3rem;
	}
	@media screen and (max-width: 320px) {
		padding: 4rem 2rem 2.5rem;
	}
}
.el-input--prefix .el-input__inner{

}
.login-form-item {
	display: flex;
	margin-bottom: 1.8rem;
}
.f-1 {
	flex: 1;
}
.random-img {
	width: 12.8rem;
	height: 4rem;
	@media screen and (max-width: 1024px) {
		width: 9.8rem;
	}
}
.login-buttons {
	margin-top: 3rem;
}
.login-foot {
	margin-top: 3.2rem;
	display: flex;
	justify-content: center;
}
.send-btn {
	width: 12.8rem;
	height: 4rem;
	padding: 0;
	@media screen and (max-width: 1024px) {
		width: 9.8rem;
		border: none;
	}
}
</style>
